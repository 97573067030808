/**
 * @license
 * Copyright© Celligence International, LLC - 2023
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
*/
import React, { lazy, Suspense, useEffect, useState } from "react";
import "@fontsource/poppins";
import "./App.css";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import Header from "./components/Header/index";
import CacheBuster from "react-cache-buster";
import appversion from "../package.json";
import graduationmortgagelocaldata from "./graduationmortgage.json";
import Banner from "./components/banner/banner.js";
import { CookieSetting } from "cookiesetting-component";
const Swmc = lazy(() => import("./components/Sunwest/sunwest.js"));
const Video = lazy(() => import("./components/Video/Video.js"));
const Footer = lazy(() => import("./components/Footer/footer.js"));

function App() {
  const [Loading, setLoading] = useState(true);
  const [BannerData, setBannerData] = useState();
  const [swmcData, setswmcData] = useState();
  const [FooterData, setFooterData] = useState();
  const [showOtherComponents, setShowOtherComponents] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.body.offsetHeight;
      const scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
      const maxScroll = documentHeight - windowHeight;
      const percentage = (scrollPosition / maxScroll) * 100;

      if (percentage > 50) {
        setShowOtherComponents(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the scroll listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array ensures the effect runs only once, like componentDidMount

  useEffect(() => {
    const fetchGraduationmortgageJSON = async () => {
      try {
        const url = process.env.REACT_APP_CDN + "graduationmortgage/graduationmortgage.json"; // Use the CDN URL from environment variables
        const response = await axios.get(url);
        const graduationmortgageJsonData = response.data;
        setBannerData(graduationmortgageJsonData.bannerDetails);
        setswmcData(graduationmortgageJsonData.swmc);
        setFooterData(graduationmortgageJsonData.Footer);
        setLoading(false);
      } catch (error) {
        console.log("Failed to fetch JSON from CDN:", error);
        // If there's an error fetching the JSON from the CDN, use the local JSON data instead
        setBannerData(graduationmortgagelocaldata.bannerDetails);
        setswmcData(graduationmortgagelocaldata.swmc);
        setFooterData(graduationmortgagelocaldata.Footer);
        setLoading(false);
      }
    };

    fetchGraduationmortgageJSON();
  }, []);
  return (
    <CacheBuster
      currentVersion={appversion.version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      // loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <div className="bodyContainer">
        {Loading ? (
          <Spinner animation="border" />
        ) : (
          <>
            <Header fetchpriority="high" />
            <>
              <Banner fetchpriority="high" BannerData={BannerData} />
              <Suspense fallback={<div></div>}>
                <Swmc swmcData={swmcData} />
              </Suspense>
              {showOtherComponents && (
                <>
                  <Suspense fallback={<div></div>}>
                    <Video swmcData={swmcData} />
                  </Suspense>
                  <Suspense fallback={<div></div>}>
                    <Footer FooterData={FooterData} BannerData={BannerData} />
                  </Suspense>
                  
                </>
              )}
            </>
          </>
        )}
         <CookieSetting
          privacyPolicyLink={process.env.REACT_APP_PRIVACY_POLICY_LINK}
          configUrl={process.env.REACT_APP_COOKIE_SETTING_JSON}
          analytics={process.env.REACT_APP_ANALYTICS_PATH}
          />
      </div>
    </CacheBuster>
  );
}

export default App;