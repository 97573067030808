import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "./banner.css";
import htmlparser from "html-react-parser";
import ContactForm from "../Contact/index.js";

const imgCDNURL = process.env.REACT_APP_CDN + "graduationmortgage/";

export default function Banner({ BannerData }) {

    function scrollToSection1() {
        const isDesktop = window.matchMedia("(min-width: 319px)").matches; // Check if the screen width is greater than or equal to 319px (adjust this value as needed)
        const navbarHeight = document.querySelector('nav').offsetHeight; // Get the height of the navigation bar
        const offset = 1; // Adjust this value to determine how much above the form the scroll will stop
        const sectionPosition = document.getElementById("contact-wrapper").offsetTop - (isDesktop ? (navbarHeight + offset) : 1); // Adjust scroll position only for desktop

        // Scroll smoothly to the section
        window.scrollTo({
            top: sectionPosition,
            behavior: "smooth",
        });
    }

    return (
        <header>
            <div className="banner">
                {window.innerWidth > 991.9 ? (
                    <video
                        autoPlay
                        muted
                        loop
                        playsInline
                        id="banner-video-desktop"
                        className="banner-deskvideo"
                        preload="auto"
                    >
                        <source src={imgCDNURL + "desktop.mp4"} type="video/mp4" />
                    </video>
                ) : (
                    <video
                        autoPlay
                        muted
                        loop
                        playsInline
                        id="banner-video-mobile"
                        className="banner-mobvideo"
                        preload="auto"
                    >
                        <source src={imgCDNURL + "mobile.mp4"} type="video/mp4" />
                    </video>
                )}
                <div className="overlay"></div>
                <Container className="bannercontainer">
                    <figure>
                        <img
                            src={imgCDNURL + "graduation-cap.svg"}
                            alt="graduation-cap"
                            width="120px"
                            height="70px"
                            className="graduation-cap"
                        />
                    </figure>
                    <h1 className="banner-heading">{htmlparser(BannerData.heading)}</h1>
                    <h2 className="banner-subheading">{htmlparser(BannerData.subheading)}</h2>
                    <Row className="features-row">
                        <Col sm={12} md={12} xl={7} lg={7} className="features-col">
                            <div className="features-container">
                                <ul className="features-list">
                                    {BannerData.features.map((feature, index) => (
                                        <li key={index} className="features">{htmlparser(feature)}</li>
                                    ))}
                                </ul>
                                <Button
                                    className="features-btn desktop-btn"
                                    variant="outline-info"
                                    onClick={scrollToSection1}
                                >
                                    Apply Now
                                </Button>
                            </div>
                        </Col>
                        <Col sm={12} md={12} xl={5} lg={5} className="features-form-col">
                            <Button
                                className="features-btn mobile-btn"
                                variant="outline-info"
                                onClick={scrollToSection1}
                            >
                                Apply Now
                            </Button>
                            <ContactForm BannerData={BannerData} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </header>
    );
}
