import React, { useCallback, useState } from "react";
import { BorrowerLeadGenerationTool } from "borrower-lead-generation-tool";
import "./index.css";
import Container from "react-bootstrap/Container";
import htmlparser from "html-react-parser";

const BorrowerLeadForm = ({ BannerData }) => {
  const [node, setNode] = useState(null);

  const formRef = useCallback((node) => {
    setNode(node);
  }, []);

  const leadSource = BannerData?.formDetails?.leadSource;
  return (
    <div className="background">
      <div id="contact-wrapper">
        <div className="mob-bg"></div>
        <Container className="formContainer">
          {node && (
            <div className="formHeader">
              <h2 className="Connect">{htmlparser(BannerData.formDetails.heading)}</h2>
              <p className="subHeading">{htmlparser(BannerData.formDetails.subheading)}</p>
            </div>
          )}
          <div className="formBody">
            <BorrowerLeadGenerationTool
              ref={formRef}
              mainBtnName="Call Me"
              loanOfficerUid="CSTC"
              leadSource={leadSource}
              showPropertyStateField={false}
              showReactPlayerVideoWindow={false}
            />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default BorrowerLeadForm;
