/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 * 
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 * 
 */

/**
 * @author Pratik Kajrolkar 
 * @date 08/04/2023
 * 
 * Determines whether the device accessing the page is a mobile, tablet, or desktop.
 *
 * @return {String} Returns "Mobile" if the device is a mobile, "Tablet" if the device is a tablet, or "Desktop" if the device is a desktop.
 */
function isMobileOrTablet() {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const isMobile = width <= 768 && height <= 1024; // Adjust the values as needed
  const isTablet = width > 768 && width <= 991; // Adjust the values as needed

  if (isMobile) {
    return true;
  } else if (isTablet) {
    return true;
  } else {
    return false;
  }
}

module.exports = {
  isMobileOrTablet
}